import React from 'react';
import { useState,useMemo } from 'react';
import {Link} from "react-router-dom";
import styles from './../../styles/PageTitle.module.css'
import ListTable from '../utils/Table'
import {useLocation} from "react-router-dom";
export default function MoviesWatched() {
  const location=useLocation()
    const [tableData,setTableData]=useState({
        tableTitle:"Movies Watched",
        disableDelete:true,
        tableHead:[
         
              {
                id: 'name',
                label: 'Movie/Episode Name',
                isSpecial:true,
                 align:"left"
              },
              {
                id: 'watch_hours',
                label: 'Watchhours',
              },
              {
                id: 'last_watch_on',
                label: 'Last Watched on',
              },
              {
                id: 'watch_by',
                label: 'Watched By',
              },
              {
                id: 'device_name',
                label: 'Device Name',
              },
              {
                id: 'location',
                label: 'Location',
              },
        ],
        tableBody:[
           
        ]})
    const [form,setForm]=useState({})
    const [isEdit,setIsEdit]=useState(false)
    useMemo(()=>{
      const temp = tableData
      temp.tableBody=location.state?.data?.map((ele)=>({...ele,name : <p style={{color:"var(--themeFontColor)"}}>{ele?.name}</p>}))
      setTableData({...temp})
    },[location.state?.data])
  return (
    <>
  

      
                 <ListTable tableData={tableData} key={"ListTable"} setForm={setForm} setTableData={setTableData} setIsEdit={setIsEdit}/>

     
    </>
  );
}
