
import { useSelector } from "react-redux"

export const TableData = () => {
  const rights = useSelector((state) => state.layout.rights)
  const role = useSelector((state) => state.layout.role)
  return {
    tableTitle: role !== "Producer" ? "Movies" : "Videos",
    onDeleteText: "Are you Sure?",
    deleteAccess: rights?.["Movie"]?.["delete"] == "true",
    onUpdateText: "Are you Sure?",
    tableHead: [
      {
        id: 'movie_name',
        label: 'Name',
        link: "/Movie/MovieDetails",
        color: "var(--gradientColor2)",
        subText: "movie_subcategory"
      },
      {
        id: 'movie_poster',
        label: 'Image',
        isImage: true
      },
    {
        id: 'movie_access1',
        label: 'Access',
        isSpecial: true,
				align: "left"
      },
      {
        id:"released_status",
        label:"Release Date"
      }
      ,
      {
        id: 'movie_language',
        label: 'Language',
      },
      ,
      {
        id: 'movieViews',
        label: 'Views',
      },
      {
        id: 'total_downloads',
        label: 'Downloads',
      },


      // role != "Producer" && 
      {
        id: 'status',
        label: 'Status',
        // isButtonDisplay: true
      },

      // role !== "Producer" &&     {
      //   id: 'edit',
      //   label: 'Update',
      //   access: rights?.["Movie"]?.["edit"] == "true"
      // },
    ].filter(e => e),
    tableBody: [

    ],
   filterColumn:  [
      {
        id: "1",
        title: "Access Type",
        name: "movie_access",
        options: ["FREE", "TVOD", "SVOD"],
      },
       {
        id: "2",
        title: "Language",
        name: "movie_language",
        options: ["English", "Hindi", "Gujarati"],
      },
      {
        id: "3",
        title: "Category",
        name: "movie_category",
        options: ["Action", "Comedy", "Drama", "Horror"],
      },
  {
        id: "3",
        title: "Sub Category",
        name: "movie_subcategory",
        options: ["Action", "Comedy", "Drama", "Horror"],
        displayOn: "movie_category",
        dependentField: "category_name",
        requredField: "subcategory_name"
      },
    {
        id: "4",
        title: "Released Status",
        name: "released_status",
        options: ["Upcoming", "Released"],
      },

    ] 
  }
}